body {
  z-index: 1;
}

.fullScreen {
  width: 100vw;
  height: 100vh;
  background-color: black;
  background-repeat: no-repeat;
  /* background-size: contain; */
  background-size: cover;
  color: white;
  background-position: top left;
}

.pageContols {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 30%;
  padding: 10px;
  z-index: 0;
}

.pageContols svg {
  background-color: #222;
  padding: 10px;
  border-radius: 6px;
  color: rgb(255, 255, 255);
  z-index: 0;
}

.pageContols button[disabled] svg {
  background-color: rgba(34, 34, 34, 0.322) !important;
  padding: 10px;
  border-radius: 6px;
  color: rgba(255, 255, 255, 0.384);
}

.menu {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #222;
  border-radius: 5px;
  padding: 10px 10px;
}

.menu svg {
  font-size: 1.5em;
  color: white;
}

.main-menu {
  display: block;
  width: 50%;
  margin: auto;
  height: auto;
  background-color: #222;
  padding: 2%;
  height: 90%;
  z-index: 9;
  position: relative;
}

button {
  background-color: rgba(255, 255, 255, 0);
  border: 0;
}

.avatar-menu {
  width: 10%;
  height: auto;
}
