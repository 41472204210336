body {
  background-color: #222;
  color: white;
}

.App {
  text-align: center;
}

p {
  max-width: 30rem;
  margin: auto;
  font-weight: 200;
  font-size: larger;
  padding: 0 5rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* align-items: center; */
  font-size: calc(10px + 2vmin);
  color: #e7e4e6;
  background-image: url("../images/code-img.png");
  background-size: cover;
  background-position: top;
}

.App-backdrop {
  max-height: 100vh;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #282c3440;
  backdrop-filter: blur(4px);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button {
  font-size: calc(10px + 2vmin);
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  position: absolute;
  width: 80vw;
  top: 0;
  left: 30px;
  /* color: #e60a0a; */
  color: #6eb517;
  /* background-color: #04181d; */
}

.navbar-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40%;
  list-style: none;
}

a {
  /* color: #e60a0a; */
  /* /* color: #e7e4e6 !important; */
  color: #6eb517;
  text-decoration: none !important;
  font-weight: 500 !important;
}

.button-prim {
  border-radius: 8px;
  background-color: rgb(0, 0, 0);
  padding: 15px;
  margin: 0 30vw;
  border: 5px solid #e7e4e6;
  border: 5px solid #6eb517;
  transition: 2s cubic-bezier(1, -0.09, 0, 1.04);
  font-weight: 900;
  /* color: #e7e4e6 !important; */
  color: #6eb517;
  text-decoration: none !important;
  font-weight: 500 !important;
}

.button-prim:hover {
  border-radius: 600px;
  transform: scale(1.05);
}

.flex {
  width: 15%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.ham-menu {
  display: none;
}

.ham-menu button {
  font-size: 2em !important;
  color: #6eb517;
  cursor: pointer;
}

button {
  background-color: rgba(255, 255, 255, 0);
  border: none;
  cursor: pointer;
}

@media only screen and (max-width: 50em) {
  .navbar-nav {
    display: none;
  }

  .ham-menu {
    display: block;
  }
  .flex-row {
    flex-direction: column;
  }
}

.side-menu {
  z-index: 10;
  width: 100% !important;
  height: 100% !important;
  background-color: #407500;
  position: absolute;
  padding-top: 10px;
}

.side-menu a {
  color: white;
}

.fade {
  animation: fadeIn 1s ease-in-out 0.5s 1 forwards none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.google_button {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(21, 103, 207);
  color: white;
  border: none;
  border-radius: 2px;
}

.google_button span {
  padding: 10px;
}

.github_button {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(39, 43, 50);
  color: white;
  border: 2px solid white;
  border-radius: 2px;
}

.github_button span {
  padding: 10px;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.user {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  padding-left: 10%;
  padding-right: 10%;
  text-align: center;
}

.avatar-menu {
  max-width: 5em;
  width: 10%;
  height: auto;
  position: fixed;
  top: 10px;
  right: 10px;
}

.profile-pic {
  max-width: 5em;
  width: 10%;
  height: auto;
  border-radius: 50%;
  position: fixed;
  top: 10px;
  left: 10px;
}

.profile-pic-dash {
  max-width: 5em;
  width: 10%;
  height: auto;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
}

.button {
  background-color: #407500;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 12px 100px;
}

.button-thin {
  background-color: #407500;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 12px 20px;
}

.toast {
  padding: 10px 30px !important;
  border-radius: 6px !important;
  font-size: 1.5em !important;
  box-shadow: none !important;
  background-color: rgb(29, 29, 29) !important;
  border: 2px solid white;
}
